import { createRouter, createWebHashHistory } from "vue-router";


const routes = [
  {
    path: "/",
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    meta: {
      title: '招牌'
    },
    component: _ => import('@/pages/home/index'),
  },
  {
    path: '/article',
    name: 'article',
    meta: {
      title: '招牌'
    },
    component: _ => import('@/pages/article/index'),
  },
  {
    path: '/download',
    name: 'download',
    meta: {
      title: '下载'
    },
    component: _ => import('@/pages/download/index'),
  },
  {
    path: '/ads',
    name: 'ads',
    meta: {
      title: '广告服务'
    },
    component: _ => import('@/pages/ads/index'),
  },
  {
    path: '/disclaimer',
    name: 'disclaimer',
    meta: {
      title: '免责声明'
    },
    component: _ => import('@/pages/disclaimer/index'),
  },
  {
    path: '/contact',
    name: 'contact',
    meta: {
      title: '联系我们'
    },
    component: _ => import('@/pages/contact/index'),
  },
  {
    path: '/about',
    name: 'about',
    meta: {
      title: '关于我们'
    },
    component: _ => import('@/pages/about/index'),
  },
  
  {
    path: '/retail',
    name: 'retail',
    component: () => import('@/pages/retail/index.vue')
  },

  // 找不到路由跳转到404
  {
    path: '/:pathMatch(.*)',
    redirect: '/not-found'
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes: routes
})

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    // 如果当前路由有meta值
    // document.title = to.meta.title
  } else {
    // 如果没有meta值，就显示默认值
    document.title = ''
  }
  next()
})

// router.onError(error => {
//   const pattern = /Loading chunk (\\w)+ failed/g
//   const isError = error.message.match(pattern)
//   const targetPath = router.history.pending.fullPath
//   if (isError) {
//     router.replace(targetPath)
//   }
// })

export default router;
