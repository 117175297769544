import { createApp } from 'vue'
import App from './App.vue'

import router from './router'
window.$router = router
import store from './store'


import './assets/css/main.css'
import './assets/css/public.css'
import '@/assets/iconfont/iconfont'

import api from '@/api';

const app = createApp(App)
app.use(router)
app.use(store)
app.config.globalProperties.api = api

import constants from './utils/constants'
app.config.globalProperties.constants = constants


app.mount('#app')




