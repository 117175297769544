<template>
  <!-- <img
      :src="constants.logo"
      style="width: 0;height: 0;padding:0;margin:0;position:absolute;z-index:-999"
  />-->
  <router-view></router-view>
</template>

<script>
// 引入组件
export default {}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
	overflow-scrolling: touch;
  scroll-behavior: smooth;
}
</style>
