import Axios from "axios";
import constants from '@/utils/constants'
let urls = require("@/config");
var url = urls[process.env.NODE_ENV];

var httpData = {
	timeout: 30000,
}
// if (process.env.NODE_ENV === "development") {
// 	let Authorization = 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiI4IiwiaWF0IjoxNjQ0MTk5ODk2LCJleHAiOjE2NDQ3ODI0MDB9.FvvaWGg4yuzZlHGEhJOaRnSZQYPH2khVzJi6a9Mc55-POMnCxuFBFHFkmU2mNS2K4EDEdxbb3Bo8gNkyZbWDyA'
// 	httpData.headers = {
// 		Authorization: getCookie('Authorization') || Authorization
// 	}

// }
httpData.baseURL = url;
const http = Axios.create(httpData);

http.interceptors.request.use(
	config => {
		if (config.method.toLowerCase() === "get" && config.data) {
			config.params = {
				...config.data
			};
		}
		// 过滤空格
		for (let p in config.params) {
			if (typeof config.params[p] == 'string') {
				config.params[p] = config.params[p].trim();
			}
		}
		for (let p in config.data) {
			if (typeof config.data[p] == 'string') {
				config.data[p] = config.data[p].trim();
			}
		}
		return config;
	},
	err => {
		return Promise.reject(err);
	}
);
http.interceptors.response.use(

	res => {
		if (res.status === 200) {
			// Vue.prototype.logout = false;
			if (res.data.code !== 200) {
				// alert(res.data.code + res.data.message)
			}
			return res.data;
		} else {

		}
	},
	error => {
		let errorInfo = {};
		if (error.response) {
			if (error.response.data) {
				let code = error.response.data.code;
				errorInfo = error.response.data;
				switch (code) {
					case 401: {
						if (process.env.NODE_ENV === "development") {
							location.href = constants.hostname + '#/login/token?reURL=' + encodeURIComponent(location.href)
						} else if (process.env.NODE_ENV === "test") {
							location.href = constants.hostname + '/h5/wx/login?app=gxxt&reURL=' + encodeURIComponent(location.href);
						} else {
							location.href = constants.hostname + '/h5/wx/login?app=gxxt&reURL=' + encodeURIComponent(location.href);
						}

						break;
					}
					case 500: {
						// alert('错误500' + errorInfo.message)
						break;
					}
					default:
						errorInfo.message = '请求失败'
				}
			}
		}
		return Promise.reject(errorInfo);
	}
);
class RequestMethod {
	constructor() {
		this.$get = this.$get.bind(this)
		this.$post = this.$post.bind(this)
		this.$put = this.$put.bind(this)
		this.$delete = this.$delete.bind(this)
		this.$upload = this.$upload.bind(this)
		this._method = this._method.bind(this)
	}

	/**
	 * 整合请求方法
	 * @param { String } url 接口路由
	 * @param { Object } axios配置 请求参数
	 */
	_method(url, options) {
		// 处理请求


		return new Promise((resolve, reject) => {
			http(url, options).then(res => {
				resolve(res)
			}).catch(error => {
				reject(error)
			})
		})
	}

	/**
	 * get方法
	 * @param { String } url 接口路由
	 * @param { Object } data 请求参数
	 */
	$get(url, data) {
		return this._method(url, {
			params: data,
			method: 'get'
		})
	}

	/**
	 * post方法
	 * @param { String } url 接口路由
	 * @param { Object } data 请求参数
	 */
	$post(url, data) {
		return this._method(url, {
			data: data,
			method: 'post',
			type: 'json'
		})
	}

	/**
	 * put方法
	 * @param { String } url 接口路由
	 * @param { Object } data 请求参数
	 */
	$put(url, data) {
		return this._method(url, {
			data: data,
			method: 'put',
			type: 'json'
		})
	}

	/**
	 * delete方法
	 * @param { String } url 接口路由
	 * @param { Object } data 请求参数
	 */
	$delete(url, data) {
		return this._method(url, {
			params: data,
			method: 'delete'
		})
	}

	/**
	 * 上传方法
	 * @param { String } url 接口路由
	 * @param { Object } data 请求参数
	 */
	$upload(url, data) {
		return this._method(url, {
			body: data,
			method: 'post',
			type: 'formData'
		})
	}
}
export default RequestMethod;

// export default http;
