module.exports = {
  // 测试环境
  // test: 'https://wxdev.bijixia.net',
  test: 'http://wxdev.bijixia.net',
  // 生产
  production: '',
	//production: 'https://wx.bijixia.net',
  // 本地开发环境
  location: 'http://develop:9000',
  // 开发环境（内网）
  // development: 'http://39.98.110.106:9000'
  development:'http://wxdev.bijixia.net'
}