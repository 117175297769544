import GetData from "@/utils/request.js"

const {
  $get,
  $post
} = new GetData();

export const getOneArticle = (id) => {
  return $get(`/h5/article/get/${id}`)
}

export const getNoteLabel = () => $get('/h5/article/getNoteLabel')

export const getArticles = ({
  categoryId,
  prev = '',
  selectStat = ''
}) => $get('/h5/article/note/list', {
  categoryId,
  prev,
  selectStat
})

export const getCarousel = type => $get(`/h5/carousel/get/${type}`)

export default {
  getOneArticle,
  getNoteLabel,
  getArticles,
  getCarousel,
}

