const state = {
};

const getters = {
};

const actions = {

};

const mutations = {
	
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
