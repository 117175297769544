import { createStore } from 'vuex'

// 各模块引入
/** 公用数据 */
import publicData from './modules/public';

export default createStore({
  modules: {
    publicData
  }
})